import { useEffect, useState } from 'react';
import Image from 'next/image';
import { FieldErrors, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import {
  Button,
  InputLabel as MuiLabel,
  MenuItem,
  Select,
  TextField,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  LABEL,
  SMAVA_CATEGORY,
  DEFAULT_SELECTION_VALUES,
  MAX_CREDIT_AMOUNT,
  DURATION_OPTIONS,
  AMOUNT_OPTIONS,
  addDotInAmount,
  removeDotInAmount,
  AMOUNT_OPTIONS_HIGH_RANGE,
  MORTGAGE_AMOUNT,
  CREDIT_CARD_AMOUNT,
  getCategoryOptions,
} from 'lib/offers/filters';
import TvLogo from 'public/assets/visuals/tv_logo.svg';

import {
  useLoanSelection,
  InputNames,
  LoanSelectionProps,
} from 'contexts/LoanSelectionContext';
import { getSessionId } from 'lib/session/sessionHelper';
import { isBaufiRedirect, getBaufiUrlForRedirect } from 'lib/baufi/helper';
import { USER_ID_COOKIE_NAME } from 'lib/optimizely/getUserId';
import { getCookie } from 'lib/cookies/cookieHelper';
import { trackButtonClick, trackLoanSelectorChange } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';
import { eventNameFromPath } from 'lib/optimizely/eventNameGenerator';
import { tagEvent } from 'lib/optimizely/EventTagger';
import { fetchPaas } from 'lib/regroute/fetchPaas';
import { urlParamsTranslator } from 'lib/urlParamsConverter/urlParamsConverter';
import { useResumeHash } from 'lib/regroute/useResumeHash';
import { getBackLinkFromHref, setBackLink } from 'lib/regroute/backLink';
import { getSessionStorage, setLocalStorage } from 'lib/storage/storage';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';
import { getPageConfig } from 'lib/page-config/helper';
import { getResolvedPathnameFromRouterAsPath } from 'lib/router/route-helper';
import { AlternativeCTAButton } from 'components/ABTestVariations/AppRedirection/AlternativeCTAButton';
import { ArrowOutward } from '@mui/icons-material';
import { Separator } from 'components/ABTestVariations/AppRedirection/Separator';
import { MobileStoresBadges } from 'components/ABTestVariations/AppRedirection/MobileStoresBadges';
import {
  APP_BUTTON_TEXT,
  getAppRedirectionExperimentLoanSelectorConfig,
} from 'components/ABTestVariations/AppRedirection/helpers';
import { ABTestKeys, Variation } from 'lib/optimizely/flagConfigs';
import useDecision from 'lib/optimizely/useDecision';

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-shrink: 0;

  .MuiTypography-root {
    margin: 8px 0;
  }

  .separator {
    .MuiTypography-root {
      margin: 0;
    }
  }
`;

const Calculator = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(7)}`,
  minWidth: '275px',
  width: '100%',
  margin: 0,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2)}`,
    background: 'none',
    border: 'none',
    boxShadow: 'none',
  },
}));

const FormControl = styled(MuiFormControl)(({ theme }) => ({
  width: '100%',
  display: 'block',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

const MultipleItems = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Label = styled(MuiLabel)`
  font-size: 14px;
`;

const FormLabel = styled(MuiFormLabel)`
  font-size: 14px;
  color: #323232;
`;

const TvLogos = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const Form = styled('form')`
  min-width: 250px;

  button {
    height: 48px;
  }
`;

export const toLocaleString = (num?: string | number | null) => {
  if (!num && num?.toString() !== '0') return num;
  return num.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

interface LoanSelectorProps {
  isCreditCardCategoryEnabled?: boolean;
  isSolarPanelCategoryEnabled?: boolean;
  hideTvBanner?: boolean;
  elevation?: number;
  ctaText?: string;
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
  additionalParams?: Record<string, unknown>;
  isAppContext?: boolean;
  appRedirectionVariation?: string | null;
}

export const LoanSelector: React.FC<LoanSelectorProps> = ({
  hideTvBanner,
  isCreditCardCategoryEnabled,
  isSolarPanelCategoryEnabled,
  elevation = 2,
  ctaText = 'Jetzt Kreditvergleich starten',
  endIcon,
  children,
  additionalParams,
  isAppContext,
  appRedirectionVariation,
}) => {
  const router = useRouter();
  const { loanSelection: savedSelection, setLoanSelection: saveSelection } =
    useLoanSelection();
  const [loanSelection, setLoanSelection] =
    useState<LoanSelectionProps>(savedSelection);
  const { resumeHash, setResumeHash } = useResumeHash();
  const [decision] = useDecision(ABTestKeys.CAT3452_Loan_amount_AB);
  const variation = decision?.variationKey || Variation.CONTROL;

  const isLivingCategory =
    loanSelection.category.toString() === SMAVA_CATEGORY.LIVING.toString();
  const isMortgageCategory =
    loanSelection.category.toString() === SMAVA_CATEGORY.MORTGAGE.toString();
  const isCarCategory =
    loanSelection.category.toString() === SMAVA_CATEGORY.CAR.toString();

  const hideDuration = isBaufiRedirect(
    loanSelection.category,
    loanSelection.amount,
  );

  const pathname = getResolvedPathnameFromRouterAsPath(router.asPath);

  const pageConfig = getPageConfig(pathname, router.query);

  const MAX_CAR_LOAN_AMOUNT = 150000;

  const numberInputs = [InputNames.VehiclePrice, InputNames.InitialPayment];

  const {
    register,
    formState: { errors },
    trigger,
  } = useForm({ mode: 'onChange' });

  const handleChange = (selectName: InputNames) => (event: any) => {
    const { value }: { value: string } = event.target;
    let newValue: number | string = value;

    if (newValue && numberInputs.includes(selectName)) {
      const amount = removeDotInAmount(value);
      const isAmountANumber = typeof amount === 'number' && !isNaN(amount);
      newValue = isAmountANumber
        ? amount
        : DEFAULT_SELECTION_VALUES[selectName];
    }

    setLoanSelection({
      ...loanSelection,
      [selectName]: newValue,
    });
    if (!numberInputs.includes(selectName)) {
      saveSelection({
        ...loanSelection,
        [selectName]: newValue,
      });
      trackLoanSelectorChange(selectName, newValue);
    }
  };

  const handleBlur = (selectName: InputNames) => async (event: any) => {
    const { value }: { value: string } = event.target;
    if (!value) {
      return selectName === InputNames.VehiclePrice
        ? resetCarValues()
        : resetCarValues(loanSelection.vehiclePrice);
    }
    if (selectName === InputNames.VehiclePrice) {
      const amount = parseInt(removeDotInAmount(value) as string, 10);
      if (amount < 500) {
        resetCarValues(500);
        return;
      }
      const loanAmount = amount - loanSelection.initialPayment;
      if (loanAmount < 500 || loanAmount < 0) {
        resetCarValues(amount, amount - 500);
      }
    }
  };

  const handleFocus = (event: any) => event.target.select();

  const handleCategoryChange = (selectName: string) => async (event: any) => {
    const newCategory = event.target?.value;

    const newCategoryIsMortgage =
      newCategory.toString() === SMAVA_CATEGORY.MORTGAGE.toString();
    const newCategoryIsCreditCard =
      newCategory.toString() === SMAVA_CATEGORY.CREDIT_CARD.toString();

    const oldCategoryIsMortage =
      loanSelection.category.toString() === SMAVA_CATEGORY.MORTGAGE.toString();
    const oldCategoryIsLiving =
      loanSelection.category.toString() === SMAVA_CATEGORY.LIVING.toString();
    const oldCategoryIsCreditCard =
      loanSelection.category.toString() ===
      SMAVA_CATEGORY.CREDIT_CARD.toString();

    if (newCategory) {
      let newAmount = loanSelection.amount;
      let newDuration = loanSelection.duration;

      if (newCategoryIsMortgage) newAmount = MORTGAGE_AMOUNT;
      if (newCategoryIsCreditCard) newAmount = CREDIT_CARD_AMOUNT;
      if (
        (oldCategoryIsMortage ||
          oldCategoryIsLiving ||
          oldCategoryIsCreditCard) &&
        !newCategoryIsMortgage &&
        !newCategoryIsCreditCard
      ) {
        const defaultAmount = variation === Variation.VARIATION_B ? 30000 : DEFAULT_SELECTION_VALUES.amount;
        newAmount = defaultAmount;
        newDuration = DEFAULT_SELECTION_VALUES.duration;
      }

      const newState = {
        ...loanSelection,
        duration: newDuration,
        amount: newAmount,
        [selectName]: newCategory,
      };
      setLoanSelection(newState);
      saveSelection(newState);
      trackLoanSelectorChange(InputNames.Category, newCategory);
    }
  };

  const handleSubmit = async (
    formValues: any,
    errors?: { vehiclePrice?: unknown; initialPayment?: unknown },
  ) => {
    // return if there are errors:
    if (formValues.category === SMAVA_CATEGORY.CAR) {
      await trigger();
      if (errors?.vehiclePrice || errors?.initialPayment) return;
    }
    // Push form values to segment
    const sessionId = getSessionId();
    const trackingProperties = {
      category: 'form_submission',
      label: 'submit',
      formName: 'stage_loanselector_form',
      formAction: 'submit',
      formLocation: window.location.pathname,
      amount: formValues.amount,
      term: formValues.duration,
      purpose: formValues.category,
      fcId: sessionId,
      segmentWriteKey: process.env.SEGMENT_WRITE_KEY,
    };

    const trackingPropertiesCleaned = JSON.parse(
      JSON.stringify(trackingProperties),
    );

    const optimizelyUserId = getCookie(USER_ID_COOKIE_NAME);
    trackButtonClick(
      TrackingEvent.LOANSELECTOR_FORM_ONSUBMIT,
      trackingPropertiesCleaned,
      {
        context: {
          Optimizely: {
            userId: optimizelyUserId,
            eventName: `form_submission-stage_loanselector_form_${eventNameFromPath(
              window.location.pathname,
            )}`,
          },
        },
      },
    );
    if (optimizelyUserId) {
      tagEvent(TrackingEvent.LOAN_VOLUME, optimizelyUserId, formValues.amount);
    }

    // Check if it is baufi redirect and if yes, proceed to baufi
    if (isBaufiRedirect(formValues.category, formValues.amount)) {
      const queryParams = new URLSearchParams({
        ...router.query,
        ...additionalParams,
      } as Record<string, string>);
      const baufiUrlForRedirect = getBaufiUrlForRedirect(
        formValues.amount,
        formValues.category,
        formValues.duration,
        formValues.initialPayment,
        queryParams,
      );
      router.push(baufiUrlForRedirect);
      return;
    }

    const { refId } = router.query;
    const { subId } = router.query;
    // Paas call and go to regroute
    const preconvertedPaasValues = {
      ...formValues,
      ...pageConfig,
      resumeHash,
      clientUuid: process.env.CLIENT_UUID,
      affiliateId: process.env.AFFILIATE_ID,
      refId,
      subId,
      ...(formValues.category.toString() === SMAVA_CATEGORY.CAR.toString() && {
        amount: formValues.vehiclePrice - formValues.initialPayment,
      }),
    };

    const voucherCode = getSessionStorage(STORAGE_CONSTANTS.actionVoucherCode);
    const voucherCampaign = getSessionStorage(
      STORAGE_CONSTANTS.actionVoucherCampaign,
    );

    const campaignUrlParams = {} as Record<string, string>;
    // change the subid to the voucher code if it exists
    if (voucherCode && voucherCampaign) {
      campaignUrlParams.subId = `${voucherCode}000000${voucherCampaign}`;
      campaignUrlParams.utm_source = 'ecrm';
      campaignUrlParams.utm_medium = 'letter';
      campaignUrlParams.utm_content = 'voucher';
      campaignUrlParams.utm_term = voucherCode;
      campaignUrlParams.utm_campaign = voucherCampaign;
      preconvertedPaasValues.subId = `${voucherCode}000000${voucherCampaign}`;
    }

    const paasPayload = urlParamsTranslator(preconvertedPaasValues);
    const paasResponse = await fetchPaas(paasPayload);
    const isResponseValid = paasResponse && paasResponse.hasError === false;
    if (!isResponseValid) return false;
    if (paasResponse?.result?.resumeHash) {
      setResumeHash(paasResponse.result.resumeHash);
    }
    const backUrl = getBackLinkFromHref();
    setBackLink(backUrl);
    setLocalStorage(STORAGE_CONSTANTS.iframeUrl, paasResponse.result.url);
    setLocalStorage(
      STORAGE_CONSTANTS.iModCurrentTraversalData,
      JSON.stringify(paasResponse.result),
    );

    const queryParams = new URLSearchParams({
      ...router.query,
      ...additionalParams,
      ...campaignUrlParams,
      ...pageConfig,
      ...(formValues.category.toString() === SMAVA_CATEGORY.CAR.toString() && {
        amount: formValues.vehiclePrice - formValues.initialPayment,
        vehiclePrice: formValues.vehiclePrice,
      }),
      backUrl,
      isAppContext: isAppContext ? 'true' : 'false',
    } as unknown as Record<string, string>);
    const antragUrlWithCurrentQuery = `/antrag?${queryParams.toString()}`;
    router.push(antragUrlWithCurrentQuery);
  };

  useEffect(() => {
    // team catapult needs a clean session storage
    sessionStorage.removeItem('IMOD_CURRENT_NAVIGATION');
  }, []);

  const resetCarValues = (
    vehiclePrice: number = 20000,
    initialPayment: number = 0,
  ) => {
    const resettedValues = {
      ...loanSelection,
      [InputNames.VehiclePrice]: vehiclePrice,
      [InputNames.InitialPayment]: initialPayment,
    };
    setLoanSelection(resettedValues);
    saveSelection(resettedValues);
  };

  const validateVehiclePrice = (value: string = '0') => {
    if (value.length < 4) return true;
    const price = parseInt(removeDotInAmount(value) as string, 10);
    if (!price) {
      return true;
    }
    const difference = price - loanSelection.initialPayment;
    if (difference > 500) {
      delete errors.initialPayment;
    }
    return price >= 500 && difference <= MAX_CAR_LOAN_AMOUNT;
  };

  const validateInitialPayment = (value: string = '0') => {
    const initialPayment = removeDotInAmount(value) as number;
    const diff = loanSelection.vehiclePrice - initialPayment;
    if (diff <= MAX_CAR_LOAN_AMOUNT) {
      delete errors.vehiclePrice;
    }
    const isValid = diff >= 500;
    if (!isValid) {
      const validInitialPayment =
        loanSelection.vehiclePrice - 500 > 0
          ? loanSelection.vehiclePrice - 500
          : DEFAULT_SELECTION_VALUES[InputNames.InitialPayment];
      resetCarValues(loanSelection.vehiclePrice, validInitialPayment);
    }
    return isValid;
  };

  const getInitialPaymentHelperText = () =>
    `Bei einem Kaufpreis von ${addDotInAmount(
      loanSelection.vehiclePrice,
    )} € darf die Anzahlung höchstens ${addDotInAmount(
      loanSelection.vehiclePrice - 500,
    )} € betragen.`;

  const categoryOptions = getCategoryOptions(
    isCreditCardCategoryEnabled,
    isSolarPanelCategoryEnabled,
  );

  //lets trigger initial validation in case user comes with values on query params:
  useEffect(() => {
    trigger();
  }, [trigger]);

  const appRedirectionExperimentConfig =
    getAppRedirectionExperimentLoanSelectorConfig(appRedirectionVariation);

  return (
    <Wrapper>
      <Calculator elevation={elevation} square>
        <Form>
          <Label id={InputNames.Category}>{LABEL.Category}</Label>
          <FormControl variant="outlined">
            <Select
              fullWidth
              labelId={InputNames.Category}
              data-testid={InputNames.Category}
              value={loanSelection[InputNames.Category]}
              onChange={handleCategoryChange(InputNames.Category)}
            >
              {categoryOptions.map((categoryOption) => (
                <MenuItem
                  key={categoryOption.value}
                  value={categoryOption.value}
                >
                  {categoryOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isCarCategory ? (
            <>
              <FormControl variant="outlined">
                <FormLabel htmlFor={InputNames.VehiclePrice}>
                  {LABEL.VehiclePrice}
                </FormLabel>
                <TextField
                  type="tel"
                  id={InputNames.VehiclePrice}
                  fullWidth
                  size="small"
                  onFocus={handleFocus}
                  inputProps={{
                    min: 500,
                    max: MAX_CREDIT_AMOUNT,
                    pattern: 'd*',
                    inputMode: 'decimal',
                    style: { paddingBottom: '10px', paddingTop: '10px' },
                  }}
                  value={addDotInAmount(loanSelection[InputNames.VehiclePrice])}
                  {...register(InputNames.VehiclePrice, {
                    onChange: handleChange(InputNames.VehiclePrice),
                    onBlur: handleBlur(InputNames.VehiclePrice),
                    validate: validateVehiclePrice,
                  })}
                  helperText={
                    errors.vehiclePrice &&
                    errors.vehiclePrice.type === 'validate'
                      ? `Der Wert muss zwischen 500 und ${addDotInAmount(
                          MAX_CAR_LOAN_AMOUNT,
                        )} € liegen.`
                      : ''
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor={InputNames.InitialPayment}>
                  {LABEL.InitialPayment}
                </FormLabel>
                <TextField
                  type="tel"
                  id={InputNames.InitialPayment}
                  size="small"
                  fullWidth
                  value={addDotInAmount(
                    loanSelection[InputNames.InitialPayment],
                  )}
                  onFocus={handleFocus}
                  inputProps={{
                    pattern: 'd*',
                    inputMode: 'decimal',
                    style: { paddingBottom: '10px', paddingTop: '10px' },
                  }}
                  {...register(InputNames.InitialPayment, {
                    onChange: handleChange(InputNames.InitialPayment),
                    onBlur: handleBlur(InputNames.InitialPayment),
                    validate: validateInitialPayment,
                  })}
                  helperText={
                    errors.initialPayment &&
                    errors.initialPayment.type === 'validate'
                      ? getInitialPaymentHelperText()
                      : ''
                  }
                />
              </FormControl>
            </>
          ) : (
            <>
              <Label id={InputNames.Amount}>{LABEL.Amount}</Label>
              <FormControl variant="outlined">
                <Select
                  fullWidth
                  labelId={InputNames.Amount}
                  data-testid={InputNames.Amount}
                  value={loanSelection[InputNames.Amount]}
                  onChange={handleChange(InputNames.Amount)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 40 * 5,
                      },
                    },
                  }}
                >
                  {isLivingCategory || isMortgageCategory
                    ? AMOUNT_OPTIONS_HIGH_RANGE.map((amountOption) => (
                        <MenuItem key={amountOption} value={amountOption}>
                          {addDotInAmount(amountOption)} €
                        </MenuItem>
                      ))
                    : AMOUNT_OPTIONS.map((amountOption) => (
                        <MenuItem key={amountOption} value={amountOption}>
                          {addDotInAmount(amountOption)} €
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </>
          )}

          <div
            data-testid="durationWrapper"
            style={{ display: hideDuration ? 'none' : 'block' }}
          >
            <Label id={InputNames.Duration}>{LABEL.Duration}</Label>
            <FormControl variant="outlined">
              <Select
                fullWidth
                labelId={InputNames.Duration}
                data-testid={InputNames.Duration}
                value={loanSelection[InputNames.Duration]}
                onChange={handleChange(InputNames.Duration)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 40 * 5,
                    },
                  },
                }}
              >
                {DURATION_OPTIONS.map((durationOption) => (
                  <MenuItem key={durationOption} value={durationOption}>
                    <MultipleItems>
                      <span>{durationOption} Monate</span>
                    </MultipleItems>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Box display="flex" flexDirection="column">
            <Button
              data-test-id="CTA"
              variant={
                appRedirectionExperimentConfig.areButtonsSwapped
                  ? 'outlined'
                  : 'contained'
              }
              color="primary"
              type="button"
              fullWidth
              endIcon={endIcon}
              style={{
                margin: hideDuration ? '20px 0 0' : '0',
                order: appRedirectionExperimentConfig.areButtonsSwapped
                  ? 1
                  : 'inherit',
              }}
              onClick={async () => {
                await handleSubmit(
                  loanSelection,
                  errors as FieldErrors<{
                    vehiclePrice?: unknown;
                    initialPayment?: unknown;
                  }>,
                );
              }}
            >
              {ctaText}
            </Button>

            {appRedirectionExperimentConfig.isMobileStoresBadgesBlackComponentVisible && (
              <Box mt={(theme) => theme.spacing(2)}>
                <MobileStoresBadges type="black" />
              </Box>
            )}

            {appRedirectionExperimentConfig.isAlternativeCTAButtonVisible && (
              <>
                {/* there is a global style overwritting in Wrapper component, we need to add another one (ideally it should be removed) */}
                <div className="separator">
                  <Separator />
                </div>
              </>
            )}

            {appRedirectionExperimentConfig.isAlternativeCTAButtonVisible && (
              <Box
                sx={{
                  order: appRedirectionExperimentConfig.areButtonsSwapped
                    ? -1
                    : 'inherit',
                }}
              >
                <AlternativeCTAButton
                  text={APP_BUTTON_TEXT}
                  endIcon={<ArrowOutward />}
                  variant={
                    appRedirectionExperimentConfig.areButtonsSwapped
                      ? 'contained'
                      : 'outlined'
                  }
                />
              </Box>
            )}
          </Box>
        </Form>
        {!hideTvBanner && (
          <TvLogos>
            <StyledImage
              src={TvLogo.src}
              alt="bekannt aus dem TV***"
              width={446}
              height={28}
              priority
            />
          </TvLogos>
        )}
        {appRedirectionExperimentConfig.isMobileStoresBadgesComponentVisible && (
          <Box mt={(theme) => theme.spacing(2)}>
            <MobileStoresBadges type="grey800" />
          </Box>
        )}
      </Calculator>
      {children}
    </Wrapper>
  );
};
